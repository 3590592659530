<template>
	<div>
		<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
		<NoData v-if="charts.length === 0 && customers.length === 0 && !pageProcesses.isLoading"></NoData>
		<div class="row" v-if="charts.length > 0">
			<GeneralDonutChart v-for="chart in charts" :data="getChartData(chart)" :title="$t('VIEWS.CustomerCenter.Dashboard.Statistic.survey') + ': ' + chart.name" :subtitle="$t('VIEWS.CustomerCenter.Dashboard.Statistic.responseRate') + ': ' + Math.round((chart.subscribercount / chart.sentcount) * 100) + '%'" :key="chart.id">
				<a href="" v-on:click.prevent="details = chart.details; detailsVisible = true;">
					<i class="fas fa-table"></i>
				</a>
			</GeneralDonutChart>
		</div>

<!--		<div class="row" v-if="customers.length > 0 && hasPermission('action.customercenter.customer')">-->
<!--			<CustomerTable :data="customers" :surveys="surveys"></CustomerTable>-->
<!--		</div>-->

		<vue-final-modal v-model="detailsVisible" classes="modal-container" content-class="modal-content" :name="'survey-details' + this._uid" :min-width="1200" :min-height="600" :max-height="'100vh'" :height="'auto'" :resize="true" :drag="true" drag-selector=".modal-drag">
			<div class="card card-custom " style="display: contents;">
				<div class="card-header card-header-tabs-line modal-drag">
					<div class="card-title w-100 d-flex justify-content-between modal-drag">
						<h3 class="card-label modal-drag">{{ $t('VIEWS.Report.Contact.contactInformation') }}</h3>
					</div>
				</div>
				<div class="card-body modal__content">
					<div class="col-12 details-table" v-if="details != null">
						<div class="mb-10" v-for="company in Object.keys(details.companies)" :key="company">
							<h2>{{ details.companies[company].name }}</h2>
							<div class="prinor-table mt-3">
								<b-table :bordered="true" :striped="true" :data="[{ name: $t('VIEWS.CustomerCenter.Dashboard.Statistic.Chart.customers'), value: 'import' }, { name: $t('VIEWS.CustomerCenter.Dashboard.Statistic.Chart.sent'), value: 'sent'}, { name: $t('VIEWS.CustomerCenter.Dashboard.Statistic.Chart.participated'), value: 'subscribed' },
								{ name: $t('VIEWS.CustomerCenter.Dashboard.Statistic.responseRate'), value: 'response_rate' }]" ref="contactDetailsTable" :icon-pack="'fa'" :sticky-header="true">
									<b-table-column field="name" :label="'Datum'" v-slot="props" sticky>
										<template>{{ props.row.name }}</template>
									</b-table-column>
									<template v-for="(value, name) in details.companies[company].history">
										<b-table-column :field="'date-' + name" :label="new Date(name).toLocaleDateString()" v-slot="props" :key="name">
											<template>{{ (isNaN(companyArray(company, name)[props.row.value])) ? '0' : (Math.round((companyArray(company, name)[props.row.value] + Number.EPSILON) * 100) / 100) }}</template>
										</b-table-column>
									</template>
								</b-table>
							</div>
						</div>
<!--						<div class="prinor-table">-->
<!--							<b-table :data="details.companies" ref="contactDetailsTable" :icon-pack="'fa'" :sticky-header="true">-->
<!--								<b-table-column field="name" :label="'Name'" v-slot="props">-->
<!--									<template>{{ props.row.name }}</template>-->
<!--								</b-table-column>-->
<!--								<template v-for="(value, name) in details.companies[Object.keys(details.companies)[0]].history">-->
<!--									<b-table-column :field="'date-' + name" :label="new Date(name).toLocaleDateString()" v-slot="props" :key="name">-->
<!--										<template>{{ props.row.name }}</template>-->
<!--									</b-table-column>-->
<!--								</template>-->
<!--							</b-table>-->
<!--						</div>-->
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between modal-drag">
					<button type="button" v-on:click.prevent="details = null; detailsVisible = false;" class="btn btn-outline-danger">
						{{ $t('GENERAL.Buttons.close') }}
					</button>
				</div>
			</div>
		</vue-final-modal>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
// import ContentLoading from '@/view/component/misc/ContentLoading';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {mapGetters} from "vuex";
import Placeholder from "@/data/survey/Placeholder";
import GeneralDonutChart from "@/view/component/misc/Charts/GeneralDonutChart";
// import CustomerTable from "@/view/component/customer-center/CustomerTable";
import ContentLoading from "@/view/component/misc/ContentLoading";
import NoData from "@/view/component/misc/NoData";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
// import NoData from "@/view/component/misc/NoData";

export default {
	name: 'CustomerCenter',
	components: {NoData, ContentLoading, GeneralDonutChart},
	// components: {NoData, ContentLoading CustomerTable},
	data() {
		return {
			charts: [],
			detailsVisible: false,
			details: null,
			customers: [],
			surveys: [],
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.CustomerCenter.title'),
			},
			{ title: this.$t('VIEWS.CustomerCenter.Dashboard.title') },
		]);
		PRINOR_PROCESSES.isLoading();
		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses']),

		personalPlaceholder: function() {
			return new Placeholder(this);
		},
	},
	methods: {
		onGet: function() {
			this.isLoading = true;
			this.get().then(data => {
				this.charts = data.data.charts;
				this.customers = data.data.customers;
				this.surveys = data.data.surveys;
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('customer-center/dashboard').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		companyArray: function(companyId, date) {
			if (this.details != null) {
				let company = this.details.companies[companyId];
				if (company != null) {
					return {
						import: company.history[date].import_total,
						sent: company.history[date].sent_total,
						subscribed: company.history[date].subscribed_total,
						response_rate: ((company.history[date].subscribed_total / company.history[date].sent_total) * 100)
					};
				}
			}

			return null;
		},
		getChartData: function(chart) {
			let arr = [];

			arr.push({
				name: this.$t('VIEWS.CustomerCenter.Dashboard.Statistic.Chart.customers'),
				amount: chart.customercount,
			});

			if (chart.contactcount !== chart.sentcount) {
				arr.push({
					name: this.$t('VIEWS.CustomerCenter.Dashboard.Statistic.Chart.contacts'),
					amount: chart.contactcount,
				});
			}

			arr.push({
				name: this.$t('VIEWS.CustomerCenter.Dashboard.Statistic.Chart.sent'),
				amount: chart.sentcount,
			});
			arr.push({
				name: this.$t('VIEWS.CustomerCenter.Dashboard.Statistic.Chart.participated'),
				amount: chart.subscribercount,
			});

			return arr;
		}
	},
};
</script>

<style scoped>
.text-valign {
	vertical-align: middle !important;
}
::v-deep .modal-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
::v-deep .modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	max-height: 90%;
	/*margin: 0 1rem;*/
	/*padding: 1rem;*/
	border: 1px solid #e2e8f0;
	border-radius: 0.25rem;
	background: #fff;
	width: 50vw;
}
::v-deep .details-table .b-table,
::v-deep .details-table .table-wrapper {
	height: 100% !important;
}
::v-deep .details-table .b-table  {
	height: 100% !important;
}
.modal__title {
	margin: 0 2rem 0 0;
	font-size: 1.5rem;
	font-weight: 700;
}
.modal__content {
	flex-grow: 1;
	overflow-y: auto;
}
.modal__close {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}
</style>
