<template>
	<div class="col-12 col-md-6 col-xl-4">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ title }}</div>
						<div class="font-size-sm mt-2">{{ subtitle }}</div>
					</div>
				</div>
				<div class="card-toolbar">
					<slot></slot>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-0 py-0">
				<div class="hello mb-3" ref="chartdiv"></div>
			</div>
		</div>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
am4core.useTheme(am4themes_animated);

export default {
	name: 'GeneralDonutChart',
	props: {
		data: Array,
		title: String,
		subtitle: String,
	},
	data() {
		return {
			chart: null,
		};
	},
	computed: {
		modalHeight() {
			return window.screen.height * 0.9 - 100;
		},
	},
	methods: {
		onHover: function(index) {
			this.chart.series.values[0].slices.values.forEach(function(slice, i) {
				if (i !== index) {
					slice.isActive = false;
				} else {
					slice.isActive = true;
				}
			});
		},
		onLeave: function() {
			this.chart.series.values[0].slices.values.forEach(function(slice) {
				slice.isActive = false;
			});
		},
		hideZero: function(ev) {
			if (ev.target.dataItem && ev.target.dataItem.value === 0) {
				ev.target.hide();
			} else {
				ev.target.show();
			}
		},
		buildChart: function() {
			let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
			chart.innerRadius = am4core.percent(30);

			chart.padding(0, 0, 0, 0);
			chart.margin(0, 0, 0, 0);

			chart.data = this.data;

			let series = chart.series.push(new am4charts.PieSeries());
			series.dataFields.value = 'amount';
			series.dataFields.category = 'name';
			series.labels.template.disabled = true;
			series.labels.template.wrap = true;
			series.calculatePercent = true;
			series.labels.template.text = '{name}: {value.value}';
			series.labels.template.fontSize = "1rem";

			series.slices.template.tooltipText = '{name}: {value.value}';
			series.hiddenState.properties.opacity = 1;
			series.hiddenState.properties.endAngle = -90;
			series.hiddenState.properties.startAngle = -90;

			series.ticks.template.events.on('ready', this.hideZero);
			series.ticks.template.events.on('visibilitychanged', this.hideZero);
			series.labels.template.events.on('ready', this.hideZero);
			series.labels.template.events.on('visibilitychanged', this.hideZero);

			series.colors.list = [
				am4core.color('#005AA9'),
				am4core.color('#009D81'),
				am4core.color('#C9D400'),
				am4core.color('#FDCA00'),
				am4core.color('#F5A300'),
				am4core.color('#EC6500'),
				am4core.color('#E6001A'),
				am4core.color('#A600B4'),
				am4core.color('#721085'),
			];

			series.slices.template.stroke = am4core.color("#fff");
			series.slices.template.strokeWidth = 2;
			series.slices.template.strokeOpacity = 1;
			series.slices.template
				// change the cursor on hover to make it apparent the object can be interacted with
				.cursorOverStyle = [
				{
					"property": "cursor",
					"value": "pointer"
				}
			];

			series.legendSettings.valueText = "{value.value}";

			chart.numberFormatter.numberFormat = '#,###.##';
			chart.seriesContainer.align = 'center';
			chart.seriesContainer.contentAlign = 'left';
			chart.seriesContainer.paddingLeft = 0;
			chart.seriesContainer.marginLeft = 0;

			chart.legend = new am4charts.Legend();
			chart.legend.position = "left";

			this.chart = chart;
		},
	},
	mounted() {
		am4core.addLicense('CH246099470');
		this.buildChart();

		// if (this.$props.question.amount > 0) {
		// 	this.buildChart();
		// } else {
		// 	this.chartFilled = false;
		// }
		//
		// this.question.answers.forEach(answer => {
		// 	if (answer.texts.length > 0) {
		// 		this.hasOthers = true;
		// 		this.othersData = answer.texts;
		// 		answer.name = this.$t('VIEWS.Report.Option.others');
		// 	}
		// });
	},
	updated: function() {
		var ref = this;
		this.$nextTick(function() {
			if (!!ref.chart && !!ref.$refs.chartdiv) {
				ref.chart.series.values[0].labels.template.maxWidth = (ref.$refs.chartdiv.clientWidth - ref.chart.series.values[0].measuredWidth) / 2.25;
			}
		});
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
};
</script>

<style scoped>
.hello {
	width: 100%;
	height: 250px;
}

.empty-chart {
	top: calc(50% - 7.5rem);
}

.empty-chart > i {
	font-size: 15rem;
	color: #e0e0e0;
}

.empty-chart-text {
	top: calc(50% - 1.5rem);
	font-size: 3rem;
	text-align: center;
}

.card-body {
	min-height: 20rem;
}
</style>
